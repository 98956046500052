
  import { Component } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { SaleOrder } from '@/entities/sales'
  import dayjs from 'dayjs'
  import { fixPrice, fixThousands } from '@/utils/general'

@Component
  export default class salePDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  carPhoto = ''
  saleOrder = plainToInstance(SaleOrder, {})
  disabledPdf = true
  logoUrl = '@/assets/genio/Logo.jpg'

  async mounted () {
    const { item: { id } } = this
    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id } },
      force: true,
    })

    this.disabledPdf = this.saleOrder.status.status.name !== 'approved'
  }

  async generatePDF (fileName) {
    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a2')

    await doc.html(this.$refs.pdfContent)
    doc.save(fileName)
    this.loading = false
  }

  async docHandler () {
    this.loading = true
    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id: this.item.id } },
      force: true,
    })

    this.saleOrder.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.saleOrder.deal.id } },
      force: true,
    })
    this.saleOrder.deal.payments = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: { id_deal: { _eq: this.saleOrder.deal.id } },
      force: true,
    })
    for (const payment of this.saleOrder.deal.payments) {
      const index = this.saleOrder.deal.payments.indexOf(payment)
      if (payment.financialAccount) {
        this.saleOrder.deal.payments[index].financialAccount = (await this.fetchData({
          query: { name: 'find', model: 'FinancialAccount' },
          filter: { id: { _eq: payment.financialAccount.id } },
          force: true,
        }))[0]
      }
    }

    const fileParameter = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: {
        _and: [
          { process: { table_name: { _eq: 'stock' } } },
          { file_type: { name: { _eq: 'photo' } } },
          { name: { _eq: 'right_front' } },
        ],
      },
    })
    const fileProcess = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.saleOrder.deal.stock.id } },
          { id_file_parameter: { _eq: fileParameter[0].id } },
        ],
      },
      force: true,
    })

    if (fileProcess[0]?.file?.largeUrl) {
      this.carPhoto = await this.loadImage(fileProcess[0]?.file?.largeUrl)
    }
    await this.getDocData()
  }

  async getDocData () {
    await this.getSystem()
    await this.getAcquirer()
    await this.getCar()
    await this.getPrices()
    await this.getSigner()
    this.getPayments()
    this.getExecutive()
    this.getMetadata()
    this.getComment()

    this.loading = false
  }

  getComment () {
    const metadata = (this.saleOrder as any).metadata
    this.$set(this.docData, 'comment', metadata?.observation)
  }

  getPayments () {
    this.docData.payments = this.saleOrder.deal.payments
  }

  async getCar () {
    const { auto } = this.saleOrder.deal
    const stockViewDetail = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id: { _eq: this.saleOrder.deal.stock.id } } },
    }))[0]
    const autoAttributes = stockViewDetail.attributes
    const chassisNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'chassis_serial')[0]
    const engineNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'engine_serial')[0]
    const mileages = stockViewDetail.attributes
      .filter(detail => detail.component.slug === 'mileage')
      .sort((prev, next) => next.value - prev.value)
    const mileage = mileages[0]

    this.$set(this.docData, 'car', {
      plate: auto.registrationPlate,
      brand: auto.version.version.model.brand.name,
      model: `${auto.version.version.model.name}  ${auto.version.version.name}`,
      year: auto.version.year.id,
      mileage: mileage.value ? fixThousands(mileage.value) : 'NO INFORMADO',
      engineNumber: engineNumberDealAttribute?.value,
      chassisNumber: chassisNumberDealAttribute?.value,
    })
  }

  async getPrices () {
    const saleProducts = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })

    const listPrice = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'product')?.[0]?.amount || 0

    const givenDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'discount')?.[0]?.amount || 0
    const postFinancingDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'post_financing_discount')?.[0]?.amount || 0
    const totalDiscount = givenDiscount + postFinancingDiscount

    const salePrice = listPrice + totalDiscount
    const transferCost = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'transfer')?.[0]?.amount || 0
    const warranty = saleProducts.filter(item => item.name === 'warranty')?.[0]
    const warrantyCost = warranty?.cost || 0
    const totalCost = listPrice + totalDiscount + transferCost + warrantyCost

    this.docData.prices = {
      list: listPrice ? fixPrice(listPrice) : '-',
      sale: salePrice ? fixPrice(salePrice) : '-',
      totalDiscount: totalDiscount ? fixPrice(Math.abs(totalDiscount)) : '-',
      total: totalCost ? fixPrice(totalCost) : '-',
      transfer: totalCost ? fixPrice(transferCost) : '-',
      services: fixPrice(transferCost + warrantyCost),
      warranty: warranty ? fixPrice(warrantyCost) : 'Sin garantía',
    }

    this.docData.warrantyBroker = warranty?.broker
  }

  async getAcquirer () {
    const acquirer = this.saleOrder.document.interveners.filter(intervener => intervener.field.name === 'buyer')?.[0]?.person ||
      this.saleOrder.document.interveners.filter(intervener => intervener.field.name === 'sale_representative')?.[0]?.person ||
      this.saleOrder.deal.lead.client
    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: acquirer?.id } },
    }))[0]
    const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
    this.docData.acquirer = {
      uid: acquirer.uid,
      name: acquirer.fullName,
      address: addressString,
      phone: acquirer.phone,
    }
  }

  async getSigner () {
    const signer = this.saleOrder.document.interveners?.filter(intervener => intervener.field.name === 'sale_representative')?.[0]?.person ||
      this.saleOrder.document.interveners.filter(intervener => intervener.field.name === 'buyer')?.[0]?.person ||
      this.saleOrder.deal.lead.client
    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: signer?.id } },
    }))[0]
    const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined

    const fullName = signer.fullName
    const uid = signer.uid

    this.docData.signer = {
      uid,
      fullName,
      address: addressString,
      phone: signer.phone,
    }
  }

  getExecutive () {
    const { capitalizeFirstLetter } = this
    const executive = this.saleOrder.deal.lead.executive.person

    this.$set(this.docData, 'executive', {
      fullName: `${capitalizeFirstLetter(executive?.firstName.trim())} ${capitalizeFirstLetter(executive?.surname.trim())}` || 'No informado',
      phone: executive?.phoneWork || 'Teléfono no informado',
      email: executive?.email.work || 'Email no informado',
      uid: executive?.uid,
    })
  }

  getMetadata () {
    const { saleOrder } = this
    const id = `${dayjs(saleOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(saleOrder.id).length)}${saleOrder.id}`
    const date = dayjs(saleOrder.deliveryDate || saleOrder.createdAt).format('DD /MM /YYYY')

    this.$set(this.docData, 'metadata', {
      id,
      date,
    })
  }

  // DEPRECATED
  addSubtitle (doc, x, y) {
    const documentId = `N°${dayjs(this.saleOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.saleOrder.id).length)}${this.saleOrder.id}`
    const hourText = `Hora: ${dayjs(this.saleOrder.deliveryDate || this.saleOrder.createdAt).format('HH:mm')}`
    const dateText = `Fecha: ${dayjs(this.saleOrder.deliveryDate || this.saleOrder.createdAt).format('DD/MM/YYYY')}`
    doc.text(documentId, x, y)
    doc.text(hourText, this.getCenterWidth(doc, hourText), y)
    doc.text(dateText, this.getAlignEnd(doc, dateText), y)
    y += 5
    y = this.separator(doc, y)
    return y
  }

  async addCarData (doc, x, y) {
    const stockViewDetail = (await this.fetchData({
      query: { name: 'find', model: 'StockViewDetails' },
      filter: { stock: { id: { _eq: this.saleOrder.deal.stock.id } } },
    }))[0]
    const mileages = stockViewDetail.attributes
      .filter(detail => detail.component.slug === 'mileage')
      .sort((prev, next) => next.value - prev.value)
    const mileage = mileages[0]
    y = this.addSectionTitle(doc, x, y, 'Datos del vehículo')
    if (this.carPhoto) {
      doc.addImage(this.carPhoto, 'JPEG', x, y - 3, 50, 35)
    }
    y = this.insertList(doc, x + (this.carPhoto ? 55 : 0), y, [
        'Patente',
        'Año',
        'Marca',
        'Modelo',
        'Kilometraje',
      ],
      [
        this.saleOrder.deal.auto.registrationPlate + '|bold',
        String(this.saleOrder.deal.auto.version.year.id),
        this.saleOrder.deal.auto.version.version.model.brand.name,
        this.saleOrder.deal.auto.version.version.model.name + ' ' + this.saleOrder.deal.auto.version.version.name + '|bold',
        mileage ? `${fixThousands(mileage.value)} kms` : 'NO INFORMADO',
      ])

    y = this.separator(doc, y)

    return y
  }

  async addPrices (doc, x, y) {
    const saleProducts = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })

    const listPrice = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'product')?.[0]?.amount || 0

    const givenDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'discount')?.[0]?.amount || 0
    const postFinancingDiscount = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'post_financing_discount')?.[0]?.amount || 0
    const totalDiscount = givenDiscount + postFinancingDiscount

    const salePrice = listPrice + totalDiscount
    const transferCost = this.saleOrder.saleOrderItems.filter(item => item.type.name === 'transfer')?.[0]?.amount || 0
    const warrantyCost = saleProducts.filter(item => item.name === 'warranty')?.[0]?.cost || 0
    const totalCost = listPrice + totalDiscount + transferCost + warrantyCost

    y = this.addSectionTitle(doc, x, y, 'Precio')

    y = this.insertList(doc, x, y, [
        'Precio de lista',
        totalDiscount !== 0 ? 'Descuento otorgado' : undefined,
        'Precio de venta',
        'Costo de traspaso',
        warrantyCost !== 0 ? 'Costo de garantía extendida' : undefined,
        'Total a cancelar',
      ].filter(item => item),
      [
        fixPrice(listPrice) + '|bold',
        totalDiscount !== 0 ? fixPrice(Math.abs(totalDiscount)) : undefined,
        fixPrice(salePrice) + '|bold',
        fixPrice(transferCost),
        warrantyCost !== 0 ? fixPrice(warrantyCost) : undefined,
        fixPrice(totalCost),
      ].filter(item => item))

    y = this.separator(doc, y)

    return y
  }

  async addPayments (doc: jsPDF, x, y) {
    const payments = this.saleOrder.deal.payments.filter(payment => !payment?.closingReason?.canceled)

    y = this.addSectionTitle(doc, x, y, 'Formas de pago')
    for (const payment of payments) {
      if (y > doc.internal.pageSize.getHeight() - 60) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc, y)
      }
      this.addDotedTitle(doc, x, y, 'Monto')
      doc.setFont(undefined, 'bold')
      doc.text(fixPrice(payment.amount), this.getAlignEnd(doc, fixPrice(payment.amount)), y)
      this.setDefaultFont(doc)

      y += 7

      const financialAccount = payment.financialAccount ? `${payment.financialAccount?.personAccount?.bankData?.bank?.alias} ${payment.financialAccount?.personAccount?.bankData?.accountNumber}` : undefined
      y = this.insertList(doc, x + 5, y, [
          'Fecha',
          'Forma de pago',
          financialAccount && payment.type.name === 'electronical_transfer' ? 'Cuenta de destino' : undefined,
          payment.type.description === 'Financiamiento' ? 'Financiera' : 'Pagador',
          'N° de abono',
        ].filter(item => item !== undefined),
        [
          payment.date.format('DD/MM/YYYY'), // Fecha
          payment.type.description, // Forma de pago
          financialAccount && payment.type.name === 'electronical_transfer' ? financialAccount : undefined, // Cuenta de destino
          (payment.type.description === 'Financiamiento' ? payment.payer.alias : payment.payer.fullName).toUpperCase(), // Pagador
          `${payment.date.format('YYYY')}${'0'.repeat(11 - String(payment.id).length)}${payment.id}`, // Nro de abono
        ].filter(item => item !== undefined)
      )

      y = this.separator(doc, y)
    }
    return y
  }

  async addServices (doc: jsPDF, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 60) {
      y = this.newPage(doc, y)
      await this.addClosingSection(doc, y)
    }
    const saleProducts = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })
    const warranty = saleProducts.filter(product => product.name === 'warranty')?.[0]
    const insurance = saleProducts.filter(product => product.name === 'insurance')?.[0]
    if (insurance) {
      insurance.company = await this.fetchData({
        query: { name: 'fetch', model: 'Person', params: { id: insurance.company.id } },
      })
    }
    const tag = saleProducts.filter(product => product.name === 'tag')?.[0]
    y = this.addSectionTitle(doc, x, y, 'Servicios')
    y = this.insertList(doc, x, y, [
      tag ? 'TAG' : undefined,
      'Seguro automotriz',
      insurance ? 'Compañía de seguro' : undefined,
      warranty && warranty?.cost !== 0 ? 'Garantía extendida' : undefined,
      warranty && warranty?.cost !== 0 ? 'N° garantía' : undefined,
    ].filter(value => value !== undefined), [
      tag ? 'Si' : 'No', // tag
      insurance ? 'Si' : 'No', // seguro
      insurance ? insurance.company.alias : undefined, // compania de seguro
      warranty && warranty?.cost !== 0 ? 'Si' : 'No', // garantia extendida
      warranty && warranty?.cost !== 0 ? `${warranty.createdAt.format('YYYY')}${'0'.repeat(13 - String(warranty.id).length)}${warranty.id}` : undefined, // n garantia
    ].filter(value => value !== undefined))

    y = this.separator(doc, y)
    return y
  }

  async addBuyer (doc: jsPDF, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 80) {
      y = this.newPage(doc, y)
      await this.addClosingSection(doc, y)
    }
    const buyer = this.saleOrder.document.interveners.filter(intervener => intervener.field.name === 'buyer')[0].person
    const signer = this.saleOrder.document.interveners.filter(intervener => intervener.field.name === 'sale_representative')?.[0]?.person
    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: buyer.id } },
    }))[0]
    const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
    y = this.addSectionTitle(doc, x, y, 'Adquiriente')
    y = this.insertList(doc, x, y, [
        buyer.type.name === 'company' ? 'Empresa' : 'Nombres',
        buyer.type.name === 'company' ? undefined : 'Apellidos',
        'Rut',
        'Teléfono',
        (buyer.email?.personal || buyer.email?.work) ? 'Mail' : undefined,
        address ? 'Dirección' : undefined,
      ].filter(item => item !== undefined),
      [
        (buyer.type.name === 'company' ? buyer.companyName : `${buyer.firstName.split(' ')[0]} ${buyer.secondName?.split(' ')?.[0] || ''}`.toUpperCase()).toUpperCase() + '|bold', // nombres
        buyer.type.name === 'company' ? undefined : `${buyer.surname.split(' ')[0]} ${buyer.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
        buyer.uid, // rut
        buyer.phone, // telefono
        (buyer.email?.personal || buyer.email?.work) || undefined, // mail
        addressString, // direccion
      ].filter(item => item !== undefined),
    )
    if (signer) {
      if (y > doc.internal.pageSize.getHeight() - 80) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc, y)
      }
      y = this.separator(doc, y)
      const address = (await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { id_person: { _eq: signer.id } },
      }))[0]
      const addressString = address ? `${address.address?.streetName} ${address.address?.streetNumber}, ${address.address?.city?.name}`.toUpperCase() : undefined
      y = this.addSectionTitle(doc, x, y, 'Firmante')
      y = this.insertList(doc, x, y, [
          'Nombres',
          'Apellidos',
          'Rut',
          'Teléfono',
          (signer.email?.personal || signer.email?.work) ? 'Mail' : undefined,
          address ? 'Dirección' : undefined,
        ].filter(item => item !== undefined),
        [
          `${signer.firstName.split(' ')[0]} ${signer.secondName?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // nombres
          `${signer.surname.split(' ')[0]} ${signer.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
          signer.uid, // rut
          signer.phone, // telefono
          (signer.email?.personal || signer.email?.work) || undefined, // mail
          addressString, // direccion
        ].filter(item => item !== undefined),
      )
    }
    y = this.separator(doc, y)
    return y
  }

  async addClosingSection (doc: jsPDF, y: number) {
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const executiveName = this.saleOrder.deal.lead.executive ? `${this.saleOrder.deal.lead.executive.person.firstName} ${this.saleOrder.deal.lead.executive.person.surname}` : 'No informado'
    this.addClosing(doc, [
        'Asesor comercial:',
        executiveName,
        `Tel: ${this.saleOrder.deal.lead.executive ? this.saleOrder.deal.lead.executive.person.phoneWork : 'No informado'}`,
        this.saleOrder.deal.lead.executive ? this.saleOrder.deal.lead.executive.person.email.work : 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async addDocumentation (doc: jsPDF, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 60) {
      y = this.newPage(doc, y)
      this.addClosingSection(doc, y)
    }
    const enablement = (await this.fetchData({
      query: { name: 'find', model: 'Enablement' },
      filter: { stock: { id: { _eq: this.saleOrder.deal.stock.id } } },
      force: true,
    }))[0]

    let documents = []

    if (enablement) {
      const enablementInspectionComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionQualification' },
        filter: {
          component: {
            _and: [
              { id_process_record: { _eq: enablement.id } },
              { process: { table_name: { _eq: 'enablement' } } },
              {
                inspection_component: { component: { category: { name: { _eq: 'Documentación' } } } },
              },
            ],
          },
        },
        force: true,
      })
      const ids = [...new Set(enablementInspectionComponent.map(item => item.component.id))]
      documents = ids.map(id => {
        const filteredById = enablementInspectionComponent.filter(item => item.component.id === id)
        return {
          id,
          name: filteredById[0].component.inspectionComponent.component.name,
          slug: filteredById[0].component.inspectionComponent.component.slug,
          locality: filteredById[0].value,
          expiracyDate: filteredById[1].value,
          serialCode: filteredById[2]?.value || undefined,
        }
      })
    }

    y = this.addSectionTitle(doc, x, y, 'Documentación')
    y = this.insertList(doc, x, y,
      [
        'Comuna permiso de circulacion|no-bullet',
        'Vencimiento',
        'Concesionaria revisión técnica|no-bullet',
        'Vencimiento',
        'SOAP|no-bullet',
        'Vencimiento',
      ],
      [
        documents.filter(document => document.slug === 'circulation_permit')[0]?.locality || 'NO INFORMADO' + '|bold',
        documents.filter(document => document.slug === 'circulation_permit')[0]?.expiracyDate || 'NO INFORMADO',
        documents.filter(document => document.slug === 'technical_review')[0]?.locality || 'NO INFORMADO' + '|bold',
        documents.filter(document => document.slug === 'technical_review')[0]?.expiracyDate || 'NO INFORMADO',
        documents.filter(document => document.slug === 'soap')[0]?.locality || 'NO INFORMADO' + '|bold',
        documents.filter(document => document.slug === 'soap')[0]?.expiracyDate || 'NO INFORMADO',
      ],
    )
    y = this.separator(doc, y)
    return y
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc, y)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, 'NOTA DE VENTA')
    y = this.addSubtitle(doc, x, y)
    y = await this.addCarData(doc, x, y)
    y = await this.addPrices(doc, x, y)
    y = await this.addPayments(doc, x, y)
    y = await this.addServices(doc, x, y)
    y = await this.addBuyer(doc, x, y)
    y = await this.addDocumentation(doc, x, y)
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
